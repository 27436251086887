import { useRouter } from 'next/router';

import { ICategoryData } from '@/api/categories/interfaces';
import { DataItem } from '@/api/posts/interfaces';
import { Wrapper } from '@/components/atoms/Wrapper';
import { DownloadCard } from '@/components/molecules/DownloadCard';
import { PromptedContentCard } from '@/components/molecules/PromptedContentCard';
import { MediaBox } from '@/components/molecules/MediaBox';

import { AdPlaceholder } from '@/components/molecules/AdPlaceholder';

import { CategoriesContainer } from './CategoriesContainer';
import { SmartAds } from '../AdCompanion';

interface ISidebarProps {
  categoriesData: ICategoryData[];
  favouritePosts: DataItem[];
  isLocalLikesCount: boolean;
  handleLocalLikesCount: () => void;
}

export const Sidebar = ({
  categoriesData,
  favouritePosts,
  isLocalLikesCount,
  handleLocalLikesCount,
}: ISidebarProps) => {
  const router = useRouter();
  const isMainPage = router.pathname === '/';

  return (
    <Wrapper className="hidden md:flex md:w-6/16 laptop:w-[322px]">
      <SmartAds variant="sidebarTop" adPlaceholder={<AdPlaceholder variant="sidebarTop" />} />
      <Wrapper className="relative gap-6" fullWidth>
        {!isMainPage && <CategoriesContainer categoriesData={categoriesData} />}
        <PromptedContentCard
          favouritePosts={favouritePosts}
          isLocalLikesCount={isLocalLikesCount}
          handleLocalLikesCount={handleLocalLikesCount}
        />
        <DownloadCard />
        <MediaBox />
      </Wrapper>
      <div className="sticky top-20">
        <SmartAds variant="sidebarBot" adPlaceholder={<AdPlaceholder variant="sidebarBot" />} />
      </div>
    </Wrapper>
  );
};
