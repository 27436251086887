import { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import ReactPaginate from 'react-paginate';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import Image from 'next/image';
import clsx from 'clsx';

import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { SmartAds } from '@/components/organisms/AdCompanion';

import { AdPlaceholder } from '../AdPlaceholder';

interface IPaginationProps {
  pageCount: number;
  isRelatedPost?: boolean;
}

export const Pagination = ({ pageCount, isRelatedPost = false }: IPaginationProps) => {
  const router = useRouter();
  const { width } = useWindowSize();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [selected, setSelected] = useState<number>(0);
  const { pathname, query } = router;
  const { page, tag, tagId, top, categoryId } = query;
  const isLastPage = selected + 1 === pageCount;
  const isRegular = width > 720;
  const pageRangeMobile = isRelatedPost ? 6 : 1;
  const pageRangeDesktop = isRelatedPost ? 7 : 5;
  const isBelowPagerAdVisible = pageCount > 0;

  const getPagePath = () => {
    if (pathname === '/userProfile/[id]') {
      return `/userProfile/${query.id}`;
    }

    if (pathname === '/categories/[categoryId]') {
      return `/categories/${categoryId}`;
    }

    if (pathname === '/post/[...slug]') {
      return '/';
    }

    return pathname;
  };

  const pagePath = getPagePath();

  const onPageChange = (event: { selected: number }) => {
    const nextPage = event.selected + 1;
    if (selected < nextPage) {
      ReactGA.event('navi_next_page_tap', {
        current_page: selected,
      });
    } else {
      ReactGA.event('navi_previous_page_tap', {
        current_page: selected,
      });
    }
    updateInterstitialActionCount();
    const routeWithoutPages = `${pagePath}${tag ? `?tag=${tag}&tagId=${tagId}` : ''}${top ? `?top=${top}` : ''}`;

    const routeWithPages = `${pagePath}?${tag ? `tag=${tag}&tagId=${tagId}&` : ''}${top ? `top=${top}&` : ''}page=${
      event.selected + 1
    }`;

    setSelected(event.selected);
    const route = event.selected === 0 ? routeWithoutPages : routeWithPages;
    window.location.href = route;
  };

  const onNextButtonClick = () => {
    ReactGA.event('navi_next_page_tap', {
      current_page: selected,
    });
    const currentPage = selected + 1;
    onPageChange({ selected: currentPage });
  };

  useEffect(() => {
    if (page) {
      setSelected(Number(page) - 1);
      return;
    }

    setSelected(0);
  }, [tag, tagId, top, page]);

  return (
    <>
      <Wrapper className={clsx(pageCount < 2 ? 'hidden' : 'flex', 'mt-8 items-center')} fullWidth>
        {!isLastPage && (
          <Button
            onClick={onNextButtonClick}
            className="focus:ring-2 focus:ring-primary-300 focus:outline-none hover:bg-primary-700 focus:bg-primary-700 !text-black text-sm font-medium px-5 py-3 mb-6 gap-2 w-fit flex"
            variant="contained"
          >
            Następna strona
            <Image src="/icons/arrow-right.svg" alt="arrow-right" width={20} height={20} />
          </Button>
        )}
        <ReactPaginate
          breakLabel="..."
          pageCount={pageCount}
          pageRangeDisplayed={isRegular ? pageRangeDesktop : pageRangeMobile}
          marginPagesDisplayed={1}
          activeClassName="bg-cover bg-primary-600 hover:opacity-70 cursor-pointer text-black py-2 px-0.5 rounded-lg"
          pageLinkClassName="hover:opacity-70 leading-tight py-2 px-3"
          nextLinkClassName="hidden"
          breakLinkClassName="hover:opacity-70 leading-tight py-2 px-3"
          containerClassName="flex items-center gap-1.5"
          previousLinkClassName="hidden"
          forcePage={selected}
          disableInitialCallback
          onPageChange={onPageChange}
          className="flex items-center list-none pl-0"
        />
      </Wrapper>
      {isBelowPagerAdVisible && (
        <SmartAds variant="belowPager" adPlaceholder={<AdPlaceholder variant="belowPager" />} />
      )}
    </>
  );
};
